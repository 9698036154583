import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const facebookRoutes: RouteRecordRaw[] = [
  {
    path: "/facebook-connexion/select",
    name: "fb-connect-select",
    component: () => import("@/views/facebook/SelectLocation.vue"),
    meta: {
      middleware: [auth],
      title: "Se connecter à Facebook | Uska",
    },
  },
  {
    path: "/facebook-connexion/location-connected",
    name: "fb-location-connected",
    component: () => import("@/views/facebook/LocationConnected.vue"),
    meta: {
      middleware: [auth],
      title: "Se connecter à Facebook | Uska",
    },
  },
]

export default facebookRoutes
