import http from "@/http-common"
import type { IGoogleSign } from "@/types/Account.types"

class FacebookService {

  authenticateWithFacebook(input: IGoogleSign): Promise<any> {
    return http.post(`/facebook/auth`, input)
  }

  getFacebookEstablishments(): Promise<any> {
    return http.get(`/auth/facebook/pages`)
  }

  getFacebookAuthUrl(): Promise<any> {
    return http.get(`/auth/facebook/auth-url`)
  }

}

export default new FacebookService()
